<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Danışman"
            label-for="id_users"
          >
            <v-select
              id="id_users"
              v-model="filterData.id_users"
              :options="users"
              label="name"
              :reduce="item => item.id"
              placeholder="Seçiniz"
              multiple
              :close-on-select="false"
              :disabled="marketingUser"
            >
              <template v-slot:option="option">
                <div class="text-info">
                  {{ option.name }}
                </div>
                <div class="font-small-2 text-warning">
                  {{ option.user_types }}
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="checkDisabled ? false:true"
            :href="downloadUrl"
            target="_blank"
          >
            <FeatherIcon icon="PieChartIcon" />
            Excel Döküm Al
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormDatepicker,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CustomerReportList',
  components: {
    vSelect,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      filterData: {
        sdate: null,
        edate: null,
        id_users: null,
      },
      marketingUser: false,
    }
  },
  computed: {
    downloadUrl() {
      const urlParams = []
      if (this.filterData.sdate) {
        urlParams.push(`sdate=${this.filterData.sdate}`)
      }
      if (this.filterData.edate) {
        urlParams.push(`edate=${this.filterData.edate}`)
      }
      if (this.filterData.id_users) {
        this.filterData.id_users.forEach(e => {
          urlParams.push(`id_users[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/Exports/reports/interview_report/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
    users() {
      return this.$store.getters['users/dataList']
    },
    checkDisabled() {
      // kullanıcı pazarlamacı ise kendisini seçtirmeden devam ettirme.
      if (this.marketingUser) {
        if (!this.filterData.sdate || !this.filterData.edate || !this.filterData.id_users) {
          return false
        }
      } else if (!this.filterData.sdate || !this.filterData.edate) {
        return false
      }
      return true
    },
  },
  created() {
    this.filterData.sdate = null
    this.filterData.edate = null
    this.filterData.id_users = null
    if (this.userData.id_user_types === this.$store.state.app.ProjectMarketingUserTypeId) {
      this.marketingUser = true
      this.filterData.id_users = [this.userData.id]
      this.getUsers(1)
    } else {
      this.marketingUser = false
      this.getUsers()
    }
  },
  methods: {
    getUsers(type = 0) {
      if (!type) {
        this.$store.dispatch('users/getDataList', {
          select: [
            'users.id as id',
            'users.name as name',
            'user_types.title as user_types',
          ],
          where: {
            'users.status': 1,
          },
        })
      } else {
        this.$store.dispatch('users/getDataList', {
          select: [
            'users.id as id',
            'users.name as name',
            'user_types.title as user_types',
          ],
          where: {
            'users.status': 1,
            'users.id': this.userData.id,
          },
        })
      }
    },
  },
}
</script>
